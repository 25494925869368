import isArray from 'lodash.isarray';
import isPlainObject from 'lodash.isplainobject';
import camelCaseString from 'lodash.camelcase';
import snakeCaseString from 'lodash.snakecase';

function keymap(value, fn) {
  if (isArray(value)) {
    return value.map(val => keymap(val, fn));
  }

  if (isPlainObject(value)) {
    const transform = (object, key) =>
      Object.assign(object, { [fn(key)]: keymap(value[key], fn) });
    return Object.keys(value).reduce(transform, {});
  }

  return value;
}

function snakecase(value) {
  return keymap(value, snakeCaseString);
}

function camelcase(value) {
  return keymap(value, camelCaseString);
}

export { camelcase, snakecase };
