import bugsnag from 'bugsnag-js';

import config from './config.erb';
import userAgent from './user-agent';

const apiKey = config.bugsnagApiKey;
const notifyReleaseStages = ['production', 'staging'];
const releaseStage = process.env.RAILS_ENV;

export const beforeSend = report => {
  if (userAgent.outdated) {
    // eslint-disable-next-line no-console
    console.log('Bugsnag disabled due to outdated user-agent.');
    report.ignore();
  } else {
    const userId = window.current_user_id;

    if (userId) {
      report.user = { id: userId }; // eslint-disable-line no-param-reassign
    }
  }
};

const instance = bugsnag({
  apiKey,
  notifyReleaseStages,
  releaseStage,
  beforeSend
});

if (userAgent.error) {
  instance.notify(userAgent.detectionError);
}

export default instance;
