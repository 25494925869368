import { UAParser } from 'ua-parser-js';

class UserAgentDetectionError extends Error {
  constructor(uaParserResult, cause) {
    super('failed to detect user agent');
    this.name = 'UserAgentDetectionError';
    this.uaParserResult = uaParserResult;
    this.cause = cause;
  }
}

export const detectUserAgent = () => {
  const ok = { outdated: false, error: null };
  let result = null;

  try {
    result = new UAParser().getResult();

    if (result.ua.includes('jsdom')) return ok;

    const {
      browser: { name, major }
    } = result;
    const majorInt = parseInt(major, 10);

    // Browser baseline: Latest (long-term support) release in 2020

    // Firefox ESR 78.0 released 2020-06-30
    if (name === 'Firefox' && majorInt < 78) return { ...ok, outdated: true };

    // Chrome Stable 87 released 2020-12-02
    if (name === 'Chrome' && majorInt < 87) return { ...ok, outdated: true };

    // Safari 14 (for MacOS and iOS) was released 2020-09-16
    if (name.includes('Safari') && majorInt < 14)
      return { ...ok, outdated: true };

    // Any detected, but unknown (= not on the disallow-list) browser is not outdated.
    return ok;
  } catch (err) {
    // Any browser that failed to detect is not outdated, but we store the error to report it.
    return { ...ok, error: new UserAgentDetectionError(result, err) };
  }
};

export default detectUserAgent();
